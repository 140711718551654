class AnnouncementPresenter {
  constructor(view, findObjectUseCase) {
    this.view = view;
    this.findObjectUseCase = findObjectUseCase;
    this.limit = 10;
    this.current = 1;
    this.where = {};
    this.documents = [];
    this.progress = false;
  }

  componentDidMount() {
    this.getData();
  }

  getData() {
    const skip = (this.current - 1) * this.limit;
    const query = {
      count: true,
      limit: this.limit,
      skip,
      where: this.where,
      include: ["all"],
      sort: { createdAt: -1 },
    };

    this.view.showProgress();
    this.progress = true;
    return this.findObjectUseCase
      .execute("announcements", query)
      .then(({ count, objects }) => {
        this.documents = this.documents.concat(objects);
        this.view.setCount(count);
        this.view.setAnnouncements(this.documents);
        this.view.hideProgress();
        this.progress = false;
      })
      .catch((error) => {
        this.progress = false;
        this.view.hideProgress();
        this.view.showError(error);
      });
  }

  onClickAnnouncement(announcement) {
    this.view.navigateTo("/announcement/details", announcement);
  }

  onSubmitSearch(word) {
    this.where = {
      title: { $regex: word, $options: "i" },
    };
    this.documents = [];
    this.current = 1;
    this.getData();
  }
}

export default AnnouncementPresenter;
