import storeRanking from "../../storeRanking";

class StoreRankPresenter {
    constructor(view, findObjectUseCase) {
        this.view = view;
        this.findObjectUseCase = findObjectUseCase;
    }

    onClickAdd() {
        this.view.showForms();
    }

    componentDidMount() {
        this.init();
    }

    init() {
        this.where = {};
    }

    onSelectForm(form) {
        this.view.navigateTo("/form/answer/" + form.id);
    }

    getAnswers() {
        if (!this.where.brand || !this.where.createdAt) return;
        const query = {
            include: ["form", "store", "answers.question", "brand"],
            where: this.where,
        };
        this.view.showProgress();
        this.findObjectUseCase.execute("form_answers", query).then((answers) => {
            const ranks = storeRanking(answers);
            this.view.setAnswers(ranks);
            this.view.hideProgress();
        });
    }

    onClickAnswer(answer) {
        this.view.navigateTo("/form/answer/" + answer.form.id + "/" + answer.id);
    }

    onChangeDate(where) {
        this.where.createdAt = where.createdAt;
        this.getAnswers();
    }

    onClickExport() {
        this.view.exportPDF();
    }

    onChangeBrand(value) {
        this.where.brand = value;
        this.getAnswers();
    }
}

export default StoreRankPresenter;
