class TrainPresenter {
  constructor(view, findUseCase) {
    this.view = view;
    this.findUseCase = findUseCase;
    this.limit = 10;
    this.current = 1;
    this.where = {};
    this.documents = [];
  }

  componentDidMount() {
    this.getData();
  }

  getData() {
    const skip = (this.current - 1) * this.limit;
    const query = {
      count: true,
      limit: this.limit,
      skip,
      where: this.where,
      sort: { createdAt: -1 },
      include: ["all"],
    };
    this.view.showProgress();
    return this.findUseCase
      .execute("learns", query)
      .then(({ count, objects }) => {
        this.documents = this.documents.concat(objects);
        this.view.setCount(count);
        this.view.setLearns(this.documents);
        this.view.hideProgress();
      })
      .catch((error) => {
        this.view.hideProgress();
        this.view.showError(error);
      });
  }

  onClickLearn(learn) {
    this.view.navigateTo("/train/details", learn);
  }

  onSubmitSearch(word) {
    this.where = {
      title: { $regex: word, $options: "i" },
    };
    this.documents = [];
    this.current = 1;
    this.getData();
  }
}

export default TrainPresenter;
