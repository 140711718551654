"use strict";

var _interopRequireDefault = require("/Users/john/Desktop/Projects/nq-component/node_modules/@babel/runtime/helpers/interopRequireDefault.js")["default"];
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _react = _interopRequireDefault(require("react"));
var _getIndexes = _interopRequireDefault(require("../../../getIndexes"));
var defaultProps = {
  schemas: []
};
function OutputPointer(_ref) {
  var field = _ref.field,
    fields = _ref.fields,
    object = _ref.object,
    target = _ref.target,
    schemas = _ref.schemas,
    indexes = _ref.indexes;
  var schema = schemas.find(function (s) {
    return s.collection === target;
  }) || {};
  var _fields = fields || schema.fields || {};
  var _indexes = indexes || _react["default"].useMemo(function () {
    var items = (0, _getIndexes["default"])(_fields);
    return items.length > 0 ? items : ['name'];
  }, [_fields]);
  var pointer = object[field];
  if (pointer) {
    return _indexes.map(function (index) {
      return pointer[index];
    }).join(' ');
  }
  return null;
}
OutputPointer.defaultProps = defaultProps;
var _default = OutputPointer;
exports["default"] = _default;