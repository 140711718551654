import BasePage from "../../base/BasePage";
import NavBar from "../../components/NavBar";
import Card from "../../components/Card";
import AnnouncementDetailsPresenter from "./AnnouncementDetailsPresenter";
import {
  findObjectUseCase,
  saveObjectUseCase,
  updateObjectUseCase,
} from "../../domain/object";
import React, { createRef } from "react";
import withContext from "../../withContext";

class AnnouncementDetailsPage extends BasePage {
  constructor(props) {
    super(props);
    this.presenter = new AnnouncementDetailsPresenter(
      this,
      findObjectUseCase(),
      saveObjectUseCase(),
      updateObjectUseCase()
    );
    this.state = {
      announcement: null,
      progress: false,
    };
    this.overflow = createRef();
  }

  componentDidMount() {
    this.presenter.componentDidMount();
  }

  getAnnouncementId() {
    const params = this.getParams();
    return params.id;
  }

  setAnnouncement(announcement) {
    this.setState({ announcement });
  }

  getAnnouncement() {
    return this.state.announcement || this.getArgument();
  }

  onClickBack() {
    this.presenter.onClickBack();
  }

  render() {
    const announcement = this.state.announcement;
    return (
      <>
        <NavBar className="shadow-sm" />
        <div className="container">
          <div className="py-3 px-lg-5 py-lg-4">
            {announcement && (
              <Card className="container col-xxl-12 ">
                <div className="px-1 py-1 my-1 text-center">
                  <img
                    className="d-block mx-auto mb-4"
                    src={announcement.image}
                    alt=""
                    width="300"
                    height="300"
                  />
                  <h1 className="display-6 fw-bold">{announcement.title}</h1>
                  <div className="col-lg-6 mx-auto">
                    <p className="lead mb-4">{announcement.description}</p>
                    {/* <div className="d-grid gap-2 d-sm-flex justify-content-sm-center">
                      <button
                        type="button"
                        className="btn btn-primary btn-lg px-4 gap-3"
                      >
                        Download Attachment
                      </button>
                    </div> */}
                  </div>
                </div>
              </Card>
            )}
          </div>
        </div>
      </>
    );
  }
}

export default withContext(AnnouncementDetailsPage);
