import React from "react";
import dateFormat from "../../dateFormat";

function ExportFormAnswer({ answers, forms }) {
  let index = -1;
  let index1 = -1;
  let numbering = 1;
  let totalYes = 0;
  let totalNo = 0;
  let totalNa = 0;
  let totalNoCount = 0;

  return (
    <>
      <div className="table-responsive mt-5 mx-5">
        <div className="d-flex justify-content-between mb-5">
          <img width="110px" src="/assets/images/figaroGroupLogo.jpg" alt="" />
          <div className="text-center">
            <h6 className="mb-0">
              <b>FIGARO COFFEE SYSTEM, INC.</b>
            </h6>
            <h6 className="mb-0">
              <b>QUALITY ASSURANCE</b>
            </h6>
            <h6 className="mb-0">
              <b>OUTLET PERFORMANCE AUDIT REPORT</b>
            </h6>
            <hr style={{ color: "red" }}></hr>
          </div>
          <img width="130px" src={answers?.brand?.image} alt="" />
        </div>
        <p className="mb-0">BRAND: <b><u>{answers.brand?.name}</u></b></p>
        <div className="d-flex justify-content-between">
          <p className="mt-0">OUTLET: <b><u>{answers.store?.name}</u></b></p>
          <p>DATE: <b><u>{dateFormat(answers.createdAt)}</u></b></p>
        </div>

        <div className="px-5 py-1">
          {forms &&
            forms.categories.map((category, i) => {
              let yes = 0;
              let na = 0;

              return (
                <React.Fragment key={category.id}>
                  <b>{category.name}</b>

                  <ol>
                    {category.questions.map((question, j) => {
                      index++;
                      answers.answers[index] = answers.answers[index] || {};
                      answers.answers[index].question = { id: question.id };

                      if (
                        answers.answers[index].answer === "YES" ||
                        answers.answers[index].answer === "NA"
                      ) {
                        yes++;
                      } else if (answers.answers[index].answer === "NA") {
                        na++;
                      }

                      return (
                        <>
                          {answers.answers[index].answer === "NO" &&
                          answers.answers[index].remarks ? (
                            <div>
                              <div className="pl-4">
                                <li className="pl-5" value={numbering++}>
                                  {answers.answers[index].remarks &&
                                    answers.answers[index].remarks
                                      .split("\n")
                                      .map((remarks) => <p>{remarks}</p>)}
                                </li>
                              </div>
                            </div>
                          ) : (j === category.questions.length - 1 &&
                              category.questions.length === yes) ||
                            (j === category.questions.length - 1 &&
                              category.questions.length === na) ? (
                            <div>
                              <p>No noted deviation.</p>
                            </div>
                          ) : (
                            ""
                          )}
                        </>
                      );
                    })}
                  </ol>
                </React.Fragment>
              );
            })}
        </div>

        <table className="table table-bordered">
          <tr className="text-center">
            <th>AREAS OF CONCERN</th>
            <th>YES</th>
            <th>NO</th>
            <th>NA</th>
          </tr>
          {forms.categories.map((category) => {
            let Totalyes = 0;
            let Totalno = 0;
            let Totalna = 0;
            let TotalNoCount = 0;

            for (const question of category.questions) {
              index1++;

              const ans = answers.answers[index1];

              if (ans.answer === "YES") {
                Totalyes = Totalyes + question.points++;
              }
              if (ans.answer === "NO") {
                Totalno = Totalno + question.points++;
                TotalNoCount++;
              }
              if (ans.answer === "NA") {
                Totalna = Totalna + question.points++;
              }
            }

            totalYes += Totalyes;
            totalNo += Totalno;
            totalNa += Totalna;
            totalNoCount += TotalNoCount;

            return (
              <>
                <tr className="text-center">
                  <td>{category.name}</td>
                  <td>{Totalyes}</td>
                  <td>{Totalno}</td>
                  <td>{Totalna}</td>
                </tr>
              </>
            );
          })}
          <tr className="text-center">
            <td colSpan="1">
              <b>Total</b>
            </td>
            <td>{totalYes}</td>
            <td>{totalNo}</td>
            <td>{totalNa}</td>
          </tr>
          <tr>
            <td className="text-end" colSpan="1">
              <h4>
                <b>AVERAGE: </b>
              </h4>
            </td>
            <td className="text-start" colSpan="3">
              <h4>
                <b>
                  {" "}
                  {answers.average >= 92.5 && "PASSED  "}
                  {answers.average < 92.5 && "FAILED  "}
                  {answers.average.toFixed(2)}
                </b>
              </h4>
            </td>
          </tr>
          <tr>
            <td className="text-center" colSpan="4">
              <b>{`NOTED DEVIATIONS = ${totalNoCount}`}</b>
            </td>
          </tr>
          <tr>
            <td colSpan="4">
              <div className="">
                <div className="">
                  <p>
                    <b>Deviations</b>
                  </p>

                  {answers.uploads?.map((pic) => (
                    <div className="d-inline-block" style={{ margin: "5px" }}>
                      <img
                        className="img-fluid"
                        src={pic}
                        alt=""
                        style={{ width: "90px", height: "90px" }}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td colSpan={"4"}>
              <p>
                <b>Others: </b>
              </p>
              <div className="w-100">
                {answers["cashCount"] &&
                  answers["cashCount"]
                    .split("\n")
                    .map((concern) => <p className="mb-0 mt-0">{concern}</p>)}
              </div>
            </td>
          </tr>
          <tr>
            <td colSpan={"4"}>
              <p>
                <b>Concerns and Recommendations</b>
              </p>
              <div className="w-100">
                {answers["concerns"] &&
                  answers["concerns"]
                    .split("\n")
                    .map((concern) => <p>{concern}</p>)}
              </div>
            </td>
          </tr>
          <tr className={answers?.concerns2 ? "" : "d-none"}>
            <td colSpan={"4"}>
              <p>
                <b>Updates</b>
              </p>
              <div className="w-100">
                {answers["concerns2"] &&
                  answers["concerns2"]
                    .split("\n")
                    .map((concern) => <p>{concern}</p>)}
              </div>
            </td>
          </tr>
          <tr className={answers?.updateUploads ? "" : "d-none"}>
            <td colSpan="4">
              <div className="">
                <div className="">
                  <p>
                    <b>Updates Attachment</b>
                  </p>

                  {answers.updateUploads?.map((pic) => (
                    <div className="d-inline-block" style={{ margin: "5px" }}>
                      <img
                        className="img-fluid"
                        src={pic}
                        alt=""
                        style={{ width: "90px", height: "90px" }}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </td>
          </tr>
          
        </table>

        <div className="grid justify-content-between mt-5">
          <div className="row">
            <div className="col-4">
              <img className="img-fluid" src={answers["signature1"]} />
              <p className="text-center">{answers["name1"]}</p>
              <p className="text-center">{answers["position1"]}</p>
            </div>
            <div className="col-4"></div>
            <div className="col-4">
              <img className="img-fluid" src={answers["signature2"]} />
              <p className="text-center">{answers["name2"]}</p>
              <p className="text-center">{answers["position2"]}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ExportFormAnswer;
