import React from "react";
import {
  InputPassword,
  InputDate,
  InputNumber,
  InputSelect,
  InputText,
  InputRelation,
  InputBooleanCheckbox,
  InputJson,
} from "nq-component";
import InputImage from "../InputImage";
import InputString from "../InputString";
import InputSignature from "../InputSignature";

import { deleteObjectUseCase, findObjectUseCase } from "../../usecases/object";
import Context from "../../AppContext";
import { saveImageUseCase } from "../../usecases/file";

const find = findObjectUseCase();
const save = saveImageUseCase();
const remove = deleteObjectUseCase()

function  InputFactory({ type, _type, field, object, schemas, index, setState, where, ...options }) {
  const context = React.useContext(Context);

  switch (_type || type) {
    case "Email":
    case "String":
      return (
        <InputString
          field={field}
          type={type.toLowerCase()}
          object={object}
          {...options}
        />
      );
    case "Password":
      return <InputPassword field={field} object={object} {...options} />;
    case "Enum":
      return (
        <InputSelect
          field={field}
          type={type.toLowerCase()}
          object={object}
          options={options.values}
          {...options}
        />
      );
    case "Number":
    case "Tel":
      return <InputNumber field={field} object={object} {...options} />;
    case "Date":
      return (
        <InputDate
          field={field}
          type={type.toLowerCase()}
          object={object}
          {...options}
        />
      );
    case "Text":
      return (
        <InputText
          field={field}
          type={type.toLowerCase()}
          object={object}
          {...options}
        />
      );
    case "Relation":
    case "Pointer":
      return (
        <InputRelation
          isMulti={type === "Relation"}
          field={field}
          type={type.toLowerCase()}
          object={object}
          schemas={schemas || context.schemas}
          find={find}
          where={where}
          {...options}
        />
      );
    case "Image":
      return (
        <InputImage
          field={field}
          type={type.toLowerCase()}
          object={object}
          save={save}
          remove={remove}
          index={index}
          {...options}
        />
      );
    case "Image":
      return (
        <InputImage
          field={field}
          type={type.toLowerCase()}
          object={object}
          save={save}
          remove={remove}
          index={index}
          {...options}
        />
      );
    case "Boolean":
      return (
        <InputBooleanCheckbox
          id={field.replace(" ", "")}
          field={field}
          type={type.toLowerCase()}
          object={object}
          {...options}
        />
      );
    case "Object":
      return (
        <InputJson id={object.id} field={field} object={object} {...options} />
      );
    case 'Signature':
          return <InputSignature
              field={field}
              object={object}
              save={save}
              {...options}
              setState={setState}
              />;
    default:
      return null;
  }
}

export default InputFactory;
