import BasePage from "../../base/BasePage";
import NavBar from "../../components/NavBar";
import React from "react";
import Card from "../../components/Card";

import withContext from "../../withContext";
import TrainingPresenter from "./TrainingPresenter";
import { findObjectUseCase } from "../../domain/object";
import { InfiniteScroll as Scroll } from "nq-component";
import { Progress } from "nq-component";
import dateFormat from "../../dateFormat";
import Search from "../../components/Search";
import NotFoundPage from "../notFound/NotFoundPage";

class TrainingPage extends BasePage {
  constructor() {
    super();
    this.presenter = new TrainingPresenter(this, findObjectUseCase());
    this.state = {
      learns: [],
      progress: false,
      count: 0,
    };
  }

  componentDidMount() {
    this.presenter.componentDidMount();
  }

  setLearns(learns) {
    this.setState({
      learns,
    });
  }

  onClickLearn(learn) {
    this.presenter.onClickLearn(learn);
  }

  setCount(count) {
    return this.setState({ count });
  }

  loadMore() {
    this.presenter.loadMore();
  }

  onChangeSearch(value) {
    this.presenter.onSubmitSearch(value);
  }

  render() {
    const count = this.state.count;
    const progress = this.state.progress;
    const learns = this.state.learns;
    return (
      <>
        <NavBar className="shadow-sm" />
        <div className="nav-scroller">
          <nav className="nav nav-tabs">
            <button className="text-nowrap nav-link active" href="#">
              <i className="bi bi-bookmark-check"></i> My Training
            </button>
            <button className="text-nowrap nav-link" href="#">
              <i className="bi bi-bookmark-check"></i> Completed
            </button>
          </nav>
        </div>

        <div className="container">
          <div className="py-3 px-lg-5 py-lg-4">
            <Card className="mb-2">
              <div className="input-group">
                <Search
                  onChange={this.onChangeSearch.bind(this)}
                  name="search"
                  autoFocus
                  className="form-control"
                  placeholder="Search Training.."
                />
                <button className="btn btn-primary">
                  <i className="bi bi-search text-white"></i>
                </button>
              </div>
            </Card>

            <Scroll
              hasMore={learns.length < count}
              loadMore={this.loadMore.bind(this)}
            >
              <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3 mt-1">
                {learns.map((learn, id) => (
                  <div className="col" key={id}>
                    <div
                      className="card shadow-sm rounded-lg h-100 mb-1 cursor-pointer"
                      onClick={this.onClickLearn.bind(this, learn)}
                    >
                      <img
                        className=" card-img-top"
                        src={learn.image}
                        alt="img"
                        width="100%"
                        height="270"
                        loading="lazy"
                      />

                      <div className="card-body">
                        <strong className="card-text">{learn.title}</strong>
                        <p
                          className="card-text text-truncate"
                          style={{ maxWidth: "900px" }}
                        >
                          {learn.description}
                        </p>
                        <div className="d-flex justify-content-between align-items-center mb-2">
                          <div>
                            <span className="badge bg-primary me-1">
                              Store Department
                            </span>
                          </div>
                          <small className="text-muted">
                            {dateFormat(learn.createdAt)}
                          </small>
                        </div>
                        <div>
                          {learn.categories.map((categories, id) => (
                            <span className="badge bg-success me-1" key={id}>
                              {categories.name}
                            </span>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              {progress && <Progress className="fs-sm">Loading ...</Progress>}
              {!progress && learns.length === 0 && (
                <NotFoundPage message="No results found" />
              )}
            </Scroll>
          </div>
        </div>
      </>
    );
  }
}

export default withContext(TrainingPage);
