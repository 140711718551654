import BasePage from "../../base/BasePage";
import NavBar from "../../components/NavBar";
import React from "react";
import Card from "../../components/Card";
import withContext from "../../withContext";
import AnnouncementPresenter from "./AnnouncementPresenter";
import { findObjectUseCase } from "../../domain/object";
import { InfiniteScroll as Scroll } from "nq-component";
import { Progress } from "nq-component";
import dateFormat from "../../dateFormat";
import Search from "../../components/Search";
import NotFoundPage from "../notFound/NotFoundPage";

class AnnouncementPage extends BasePage {
  constructor() {
    super();
    this.presenter = new AnnouncementPresenter(this, findObjectUseCase());
    this.state = {
      announcements: [],
      progress: false,
      count: 0,
    };
  }

  componentDidMount() {
    this.presenter.componentDidMount();
  }

  setAnnouncements(announcements) {
    this.setState({
      announcements,
    });
  }

  onClickAnnouncement(announcement) {
    this.presenter.onClickAnnouncement(announcement);
  }

  getAnnouncementId() {
    const params = this.getParams();
    return params.id;
  }

  setCount(count) {
    return this.setState({ count });
  }

  loadMore() {
    this.presenter.loadMore();
  }

  onChangeSearch(value) {
    this.presenter.onSubmitSearch(value);
  }

  render() {
    const count = this.state.count;
    const progress = this.state.progress;
    const announcements = this.state.announcements;

    return (
      <>
        <NavBar className="shadow-sm" />
        <div className="container">
          <div className="py-3 px-lg-5 py-lg-4">
            <Card className="mb-2">
              <div className="input-group">
                <Search
                  onChange={this.onChangeSearch.bind(this)}
                  name="search"
                  autoFocus
                  className="form-control"
                  placeholder="Search Announcement.."
                />
                <button className="btn btn-primary">
                  <i className="bi bi-search text-white"></i>
                </button>
              </div>
            </Card>
            <Card className="mb-3">
              <h6 className="border-bottom pb-2 mb-0">
                <i className="bi bi-megaphone me-1"></i> Announcement
              </h6>
              <Scroll
                hasMore={announcements.length < count}
                loadMore={this.loadMore.bind(this)}
              >
                {announcements.map((announcement, id) => (
                  <div
                    key={id}
                    onClick={this.onClickAnnouncement.bind(this, announcement)}
                    className="mb-1 cursor-pointer"
                  >
                    <div className="d-flex text-muted pt-3">
                      <img
                        className="bd-placeholder-img flex-shrink-0 me-3 my-2"
                        src={announcement.image}
                        alt="antimg"
                        width="50"
                        height="40"
                        loading="lazy"
                      />
                      <div className="pb-3 mb-0 small lh-sm border-bottom w-100">
                        <strong className="d-block">
                          {announcement.title}
                        </strong>
                        <small className="d-block">
                          {announcement.description}
                        </small>
                        <small className="d-block">
                          {dateFormat(announcement.createdAt)}
                        </small>
                      </div>
                    </div>
                  </div>
                ))}
                {progress && <Progress className="fs-sm">Loading ...</Progress>}
                {!progress && announcements.length === 0 && (
                  <NotFoundPage message="No results found" />
                )}
              </Scroll>
            </Card>
          </div>
        </div>
      </>
    );
  }
}

export default withContext(AnnouncementPage);
