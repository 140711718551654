import { upload } from "@testing-library/user-event/dist/upload";
import React from "react";
import classNames from "../../classNames";
import { saveFileUseCase } from "../../domain/file";
// import { saveFileUseCase } from "../../domain/file";

const defaultProps = {
  object: {},
};

function InputFile({ className, field, object, ...props }) {
  const classes = classNames("form-control", className);
  const value = object[field];

  function onChange(e) {
    const files = Array.prototype.slice.call(e.target.files);
    if (files.length > 0) {
      let images = [];
      // let imagesGroup = [];
      for (let item = 0; item < files.length; item++) {
        saveFileUseCase()
          .execute(files[item])
          .then((result) => {
            // images = {
            //   name : result.name,
            //   url : result.url
            // }
            images.push(result.url);
            object[field] = images;
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
    // console.log(object);
  }

  // const handleUploadFiles = (files) => {
  //   const uploaded = value;
  //   files.some((file) => {
  //     uploaded.push(file);
  //   });
  //   console.log(uploaded);
  // };

  return (
    <input
      type="file"
      defaultValue={value}
      className={classes}
      name={field}
      onChange={onChange}
      multiple
      {...props}
    />
  );
}

InputFile.defaultProps = defaultProps;
export default InputFile;
