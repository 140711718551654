"use strict";

var _interopRequireDefault = require("/Users/john/Desktop/Projects/nq-component/node_modules/@babel/runtime/helpers/interopRequireDefault.js")["default"];
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _objectToOption = _interopRequireDefault(require("./objectToOption"));
function GetOption(target, indexes, word, callback, find, where) {
  var _this = this;
  this.query = {
    count: true,
    limit: 100,
    where: where
  };
  if (word && indexes.length > 0) {
    this.query.where['$or'] = indexes.map(function (index) {
      var or = {};
      or[index] = {
        '$regex': word,
        '$options': 'i'
      };
      return or;
    });
  }
  // if (indexes.length > 0) {
  //     const sort = {};
  //     indexes.forEach(index => {
  //         sort[index] = -1;
  //     })
  //     this.query["$sort"] = sort;
  // }
  clearTimeout(this.timeout);
  this.timeout = setTimeout(function () {
    find.execute(target, _this.query).then(function (_ref) {
      var objects = _ref.objects;
      callback(objects.map(function (obj) {
        return (0, _objectToOption["default"])(obj, indexes);
      }).sort(function (a, b) {
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }
        return 0;
      }));
    });
  }, 300);
}
var _default = GetOption;
exports["default"] = _default;