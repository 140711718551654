import BasePage from "../../base/BasePage";
import NavBar from "../../components/NavBar";
import Card from "../../components/Card";
import {
  findObjectUseCase,
  saveObjectUseCase,
  updateObjectUseCase,
} from "../../domain/object";
import React, { createRef } from "react";
import withContext from "../../withContext";
import TrainingDetailsPresenter from "./TrainingDetailsPresenter";

class TrainingDetailsPage extends BasePage {
  constructor(props) {
    super(props);
    this.presenter = new TrainingDetailsPresenter(
      this,
      findObjectUseCase(),
      saveObjectUseCase(),
      updateObjectUseCase()
    );
    this.state = {
      learn: null,
      progress: false,
    };
    this.overflow = createRef();
  }

  componentDidMount() {
    this.presenter.componentDidMount();
  }

  getAnnouncementId() {
    const params = this.getParams();
    return params.id;
  }

  setAnnouncement(learn) {
    this.setState({ learn });
  }

  getAnnouncement() {
    return this.state.learn || this.getArgument();
  }

  onClickBack() {
    this.presenter.onClickBack();
  }

  render() {
    const learn = this.state.learn;
    return (
      <>
        <NavBar className="shadow-sm" />
        <div className="container">
          <div className="py-3 px-lg-5 py-lg-4">
            <div
              id="vid-content"
              className="container-fluid d-flex flex-column justify-content-center"
            >
              <div className="row justify-content-center">
                {learn && (
                  <div className="col-sm-7">
                    <Card>
                      <div className="embed-responsive embed-responsive-16by9">
                        <iframe
                          width="100%"
                          height="315"
                          src={learn.video_url}
                          title="YouTube video player"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                        ></iframe>
                      </div>

                      <div className="d-flex justify-content-between">
                        <div className="leftitem d-flex">
                          <div className="p-1 align-self-center">
                            <img
                              src={learn.image}
                              width="50"
                              salt=""
                              alt="learn"
                            />
                          </div>
                          <div className="p-1 align-self-center">
                            <h6 className="m-0 p-0">John Cena</h6>
                            <span>
                              <small>Uploader</small>
                            </span>
                          </div>
                        </div>

                        <div className="p-1 align-self-center">
                          <h6 className="m-0 p-0">Upload Date</h6>
                          <span className="float-end">
                            <small>{learn.createdAt}</small>
                          </span>
                        </div>
                      </div>

                      <div className="container m-0 p-0 pt-4">
                        <h5 className="ms-2 me-2">
                          <strong>{learn.title}</strong>
                        </h5>

                        <p className="p-1 mt-1">{learn.description}</p>

                        {/* <div className="mt-4 mb-5 ms-3 me-3 d-grid">
                          <button
                            className="btn-success btn fs-sm py-2"
                            type="submit"
                            style={{
                              cursor: "pointer",
                              borderRadius: "1.5rem",
                              color: "white",
                            }}
                          >
                            <strong>Confirm and Take Exam</strong>
                          </button>
                        </div> */}
                      </div>
                    </Card>
                  </div>
                )}
                {/* <div className="col-sm-5 container-fluid">
                  <div className="container-fluid d-flex align-items-center justify-content-between">
                    <h5 className="mb-0">List of Videos</h5>
                    <h5 className="mb-0">(1 of 5)</h5>
                  </div>
                  <div
                    className="card card-style mt-2 me-2 mb-2 ms-2 border-0 overflow-auto"
                    style={{ height: "560px" }}
                  >
                    <div className="content mb-0">
                      <a
                        href="/training_videos.html"
                        className="d-flex p-3 text-dark"
                      >
                        <div className="align-self-center">
                          <img
                            src="../../assets/images/Retail Training22.png"
                            width="120"
                            className="rounded-3 me-3 border border-2 border-light"
                            alt="img"
                          />
                        </div>
                        <div className="align-self-center">
                          <p className="mb-0" style={{ fontSize: "14px" }}>
                            <strong>Training Video 8</strong>
                          </p>
                          <p
                            className="mb-0"
                            style={{ fontSize: "12px", width: "70%" }}
                          >
                            Basic Steps for Greeting a Walk-In Customers dolor
                            sit amet.
                          </p>
                        </div>
                        <div className="position-absolute align-self-center end-0 pe-3">
                          <span
                            className="float-end p-1"
                            style={{ fontSize: "12px" }}
                          >
                            <i className="bi bi-check-circle-fill text-muted"></i>
                          </span>
                        </div>
                      </a>
                      <div className="divider mt-1 border-bottom"></div>
                      <div className="divider mt-1 border-bottom"></div>
                      <a
                        href="/training_videos.html"
                        className="d-flex p-3 text-dark"
                      >
                        <div className="align-self-center">
                          <img
                            src="../../assets/images/Retail Training22.png"
                            width="120"
                            className="rounded-3 me-3 border border-2 border-light"
                            alt="img"
                          />
                        </div>
                        <div className="align-self-center">
                          <p className="mb-0" style={{ fontSize: "14px" }}>
                            <strong>Training Video 8</strong>
                          </p>
                          <p
                            className="mb-0"
                            style={{ fontSize: "12px", width: "70%" }}
                          >
                            Basic Steps for Greeting a Walk-In Customers dolor
                            sit amet.
                          </p>
                        </div>
                        <div className="position-absolute align-self-center end-0 pe-3">
                          <span
                            className="float-end p-1"
                            style={{ fontSize: "12px" }}
                          >
                            <i className="bi bi-check-circle-fill text-muted"></i>
                          </span>
                        </div>
                      </a>
                      <div className="divider mt-1 border-bottom"></div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withContext(TrainingDetailsPage);
